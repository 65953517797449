@import url("../../Assets/theme.css");

body {
  background-color: var(--body-color);
}

/* Header  */
#Navigation-bar {
  position: fixed;
  /* background-color: var(--primary-color-Navy-light); */
  padding: 15px 20px;
  width: 100%;
  z-index: 1;
}
.Header-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.logo-header {
  display: flex;
  align-items: center;
  transition: 0.5s;
}

.logo-header h1 {
  color: var(--nav-text);
}

.navigation ul {
  display: flex;
  align-items: center;
  background: none;
}
.navigation ul a {
  text-decoration: none;
  margin-left: 25px;
}
.navigation ul .navLink {
  list-style: none;
  margin-left: 25px;
  font-size: 20px;
  color: var(--nav-text);
  font-weight: 400;
}
.navigation ul .navLink:hover {
  color: var(--primary-color-orange-light);
}
.sticky .navigation ul .navLink {
  color: var(--primary-color-Navy-light);
}
.sticky .navigation ul .navLink:hover {
  color: var(--primary-color-blue-light);
}

.sticky {
  background: var(--primary-color-orange-light);
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  height: 70px;
}

.sticky .logo-header {
  margin-left: -100px;
  transition: 0.5s;
}
.sticky .navbar {
  margin-right: 10px;
  transition: 0.2s;
}

.sticky .login-btn {
  background: var(--primary-color-Navy-light);
  color: var(--nav-text);
  text-align: center;
}

.sticky .logo-header h1 {
  color: var(--primary-color-Navy-light);
}

button {
  all: unset;
}

.login-btn {
  background: var(--primary-color-orange-light);
  padding: 6px 20px;
  border-radius: 6px;
  color: var(--primary-color-Navy-light);
  transition: var(--tran-03);
}

.login-btn:hover {
  background: none;
  padding: 4px 18px;
  border: 2px solid var(--nav-text);
  color: var(--nav-text);
  transition: 0.2s;
}

/* ==== Header Responsive===== */

/*==== For screens smaller than 800px ====*/

@media screen and (max-width: 819px) {
  /*====Hide the navigation content ====*/
  .Navigation-content .navigation {
    display: none;
  }
  .logo-header {
    margin-left: -280px;
  }

  /*==== Show the hamburger menu ====*/
  .hamburger-menu {
    color: var(--nav-text);
    cursor: pointer;
  }
  .Header-container {
    display: flex;
    flex-direction: column;
    gap: 50px;
    align-items: center;
  }

  .logo-header {
    align-items: center;
    transition: 0.5s;
    margin-left: 10px;
  }
  .dropdown-menu {
    margin-top: -50px;
  }
  .navbar {
    margin-right: 10px;
    transition: 0.2s;
  }
  .navigation ul .navLink {
    color: var(--primary-color-Navy-light);
  }
  .navigation ul .navLink:hover {
    color: var(--primary-color-blue-light);
  }
  .sticky .navigation ul .navLink {
    color: var(--primary-color-Navy-light);
  }
  .sticky .navigation ul .navLink:hover {
    color: var(--primary-color-blue-light);
  }
  .menu-one {
    padding-left: -270px;
  }
  .menu-two {
    padding-left: 130px;
  }
  .menu-three {
    padding-left: 84px;
  }
  .menu-three a {
    cursor: pointer;
    color: var(--nav-text);
  }
  .why-tracker {
    display: flex;
    flex-direction: column;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 100px;
    gap: 80px;
  }
}
/*==== For screens smaller than 375px ====*/
@media only screen and (max-width: 375px) {
  .hamburger-menu {
    color: var(--nav-text);
    cursor: pointer;
  }
  .Header-container {
    display: flex;
    flex-direction: column;
    gap: 50px;
    align-items: center;
  }

  .navigation ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--primary-color-orange-light);
    width: 375px;
    height: 549px;
    margin-left: -670px;
  }
  .logo-header {
    align-items: center;
    transition: 0.5s;
    margin-left: -630px;
  }
  .dropdown-menu {
    margin-top: -50px;
  }
  .navbar {
    margin-right: 10px;
    transition: 0.2s;
  }

  .menu-one {
    padding-left: 0px;
  }
  .menu-two {
    padding-left: 100px;
  }
  .menu-three {
    padding-left: 100px;
  }
  .sticky {
    display: none;
  }
  .why-tracker {
    display: flex;
    flex-direction: column;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 100px;
    gap: 80px;
  }
}

/*==== For screens bigger than 375px and smaller than 500====*/
@media only screen and (max-width: 500px) {
  .hamburger-menu {
    color: var(--nav-text);
    cursor: pointer;
  }
  .Header-container {
    display: flex;
    flex-direction: column;
    gap: 50px;
    align-items: center;
  }

  .navigation ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--primary-color-orange-light);
    width: 410px;
    height: 510px;
    margin-left: -80px;
    box-shadow: -10px 10px 20px 5px black;
  }
  .logo-header {
    align-items: center;
    transition: 0.5s;
    margin-left: -30px;
  }
  .dropdown-menu {
    margin-top: -50px;
  }
  .navbar {
    margin-right: 10px;
    transition: 0.2s;
  }

  .menu-one {
    padding-left: 10px;
  }
  .menu-two {
    padding-left: 100px;
  }
  .menu-three {
    padding-left: 90px;
  }
  .sticky {
    display: none;
  }
  .Tracker-disc .Bumpy-Pie .why-tracker {
    display: flex;
    flex-direction: column;
    grid-template-rows: 100px;
    gap: 80px;
  }
  .front .Enter-site-home h1 {
    font-size: 48px;
    display: grid;
    align-items: center;
    margin-left: 60px;
    color: white;
  }
  .front .Enter-site-home button {
    margin-left: 70px;
    width: 200px;
  }
  .partners .partner-cont {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-template-rows: 100px;
    gap: 10px;
    padding-top: 10px;
  }
  .partners .partner-cont .box img {
    background-color: white;
    border-radius: 5%;
    width: 150px;
    height: 65px;
  }
  .row .footer-col h4,
  span {
    font-size: 10px;
  }
  .row .footer-col .partners img {
    width: 70px;
    height: 30px;
  }

  .container .row {
    display: flex;
    margin-left: 30px;
    justify-content: space-between;
  }
  .footer-col h4::before {
    display: none;
  }
}

/*==== For screens bigger than 500px and smaller than 768 ====*/

@media only screen and (max-width: 500px) {
  .hamburger-menu {
    color: var(--nav-text);
    cursor: pointer;
  }
  .Header-container {
    display: flex;
    flex-direction: column;
    gap: 50px;
    align-items: center;
  }

  .navigation ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--primary-color-orange-light);
    width: 400px;
    height: 510px;
    margin-left: -75px;
    box-shadow: 5px 10px 20px 5px black;
    border-radius: 10px;
  }

  .logo-header {
    align-items: center;
    transition: 0.5s;
    margin-left: -30px;
  }
  .dropdown-menu {
    margin-top: -50px;
  }
  .navbar {
    margin-right: 10px;
    transition: 0.2s;
  }

  .menu-one {
    padding-left: 0px;
  }
  .menu-two {
    padding-left: 100px;
  }
  .menu-three {
    padding-left: 90px;
  }
  .sticky {
    display: none;
  }

  .Tracker-disc .why-tracker {
    display: flex;
    flex-direction: column;
    grid-template-rows: 100px;
    gap: 80px;
  }
  .front .Enter-site-home h1 {
    font-size: 48px;
    display: grid;
    align-items: center;
    margin-left: 60px;
    color: white;
  }
  .front .Enter-site-home button {
    margin-left: 70px;
    width: 200px;
  }
  .partners .partner-cont {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-template-rows: 100px;
    gap: 10px;
    padding-top: 10px;
  }
  .partners .partner-cont .box img {
    background-color: white;
    border-radius: 5%;
    width: 150px;
    height: 65px;
  }
  .row .footer-col h4,
  span {
    font-size: 10px;
  }
  .row .footer-col .partners img {
    width: 70px;
    height: 30px;
  }

  .container .row {
    display: flex;
    margin-left: 30px;
    justify-content: space-between;
  }
  .footer-col h4::before {
    display: none;
  }
}

/*==== For screens larger than 800px and smaller than 1020px ====*/

/* ===== For screen bigger than 769 ===== */
@media screen and (min-width: 801px) {
  .Navigation-content {
    display: block;
  }
  .logo-header {
    margin-left: -100px;
  }

  /*==== Hide the hamburger menu ====*/
  .hamburger-menu {
    display: none;
    width: 0px;
  }
}

/*==== login style ====*/
.try {
  width: 100vw;
  height: 100vh;
}
.Back-login {
  position: absolute;
  border-top: 615px solid var(--primary-color-Navy-light);
  border-right: 615px solid transparent;
}

.login-contents {
  display: flex;
  justify-content: right;
}
.logo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 10px;
}
.logo a {
  display: flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
}

.logo img {
  width: 85px;
  height: 45px;
  padding-left: 40px;
}
.logo h1 {
  color: var(--primary-color-Navy-light);
  font-size: 25px;
  font-family: "Poppins", sans-serif;
}

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-15%, -35%);
}
.newusr {
  display: flex;
  justify-content: center;
}

.newusr h1 {
  padding-bottom: 10px;
  font-size: 18px;
  color: var(--primary-color-Navy-light);
  font-family: "Poppins", sans-serif;
}
.newusr h1 .span {
  color: var(--primary-color-orange-light);
}
.form-cont input {
  padding: 10px;
  width: 100%;
  height: 40px;
  color: var(--primary-color-Navy-light);
  margin-bottom: 6px;
  border: 1px solid var(--primary-color-orange-light);
  border-radius: 5px;
  caret-color: var(--primary-color-orange-light);
}
input:focus {
  color: var(--primary-color-orange-light);
}
.btn-login {
  background: var(--primary-color-red-light);
  border-radius: 5px;
  padding: 6px 20px;
  color: var(--nav-text);
  text-decoration: none;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  margin: 8px 0;
  text-decoration: none;
  cursor: pointer;
  transition: var(--tran-04);
}
.btn-login:hover {
  background: var(--primary-color-Navy-light);
  transition: var(--tran-04);
}
.forget {
  text-decoration: none;
}
.forget h4 {
  color: var(--primary-color-red-light);
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  text-align: center;
}
.errmsg {
  color: var(--error-color);
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  text-align: center;
}
.form-reg-cont input[type="text"] {
  padding: 10px;
  height: 40px;
  margin-bottom: 6px;
  margin-right: 6px;
  color: var(--primary-color-Navy-light);
  border: 1px solid var(--primary-color-orange-light);
  border-radius: 5px;
  caret-color: var(--primary-color-orange-light);
}
.form-reg-cont input[type="email"],
.form-reg-cont input[type="password"] {
  padding: 10px;
  width: 81%;
  height: 40px;
  margin-bottom: 6px;
  color: var(--primary-color-Navy-light);
  border: 1px solid var(--primary-color-orange-light);
  border-radius: 5px;
  caret-color: var(--primary-color-orange-light);
}
.usr {
  position: relative;
  padding-top: 20px;
  display: flex;
  justify-content: center;
}
.usr h1 {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
}
.error,
.errmsg {
  color: red;
}
.password-progress {
  display: flex;
  flex-direction: row;
  color: #757575;
  align-items: center;
}

/* Body */
.backimg {
  padding-top: 65px;
  background: url("../../Assets/images/map-background.svg") no-repeat;
  background-size: 125% 620px;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: var(--primary-color-Navy-light);
}

.Enter-site-home {
  position: absolute;
  left: 10%;
  top: 42%;
  transform: translate(-20%, -50%);
  text-align: center;
  padding-top: 20vh;
  width: 55%;
}

.Enter-site-home h1 {
  font-size: 50px;
  color: white;
}

.Enter-site-home button {
  all: unset;
}
.header-yellow {
  color: var(--primary-color-orange-light);
  font-size: 155%;
}
.Enter-site-home button {
  margin-top: 40px;
  width: 200px;
  height: 60px;
  background: var(--primary-color-orange-light);
  border-radius: 15px;
  color: var(--nav-text);
  font-size: 18px;
  text-align: center;
  line-height: 10px;
  font-family: sans-serif;
  text-transform: uppercase;
  animation: animate-pulse 3s linear infinite;
  cursor: pointer;
}
@keyframes animate-pulse {
  0% {
    box-shadow: 0 0 0 0 var(--primary-color-orange-light),
      0 0 0 0 var(--primary-color-orange-light);
  }
  40% {
    box-shadow: 0 0 0 20px rgba(255, 109, 74, 0),
      0 0 0 0 var(--primary-color-orange-light);
  }
  80% {
    box-shadow: 0 0 0 30px rgba(255, 109, 74, 0),
      0 0 0 10px rgba(255, 109, 74, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 109, 74, 0), 0 0 0 10px rgba(255, 109, 74, 0);
  }
}
.Enter-site-home button:hover:before {
  transform: scale(1);
  opacity: 1;
  transition: transform 0.5s ease-out, opacity 1s ease-out;
  animation: heartbeat 1s ease-out infinite;
}

@keyframes heartbeat {
  from {
    transform: scale(0);
    opacity: 0.5;
  }
  to {
    transform: scale(1);
    opacity: 0;
  }
}
.bottomforthefront {
  position: absolute;
  left: 45%;
  top: 92.4%;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  border-bottom: 50px solid white;
}
/* === Body Responsive=== */
@media only screen and (max-width: 375px) {
  .front .Enter-site-home .with1{
    font-size: 30px;
    margin-left: 50%;
    margin-top: -80px;
  }
  .header-yellow {
    color: var(--primary-color-orange-light);
    font-size: 130%;
    margin-left: -15px;
    margin-top: 10px;

  }
  
  #Navigation-bar {
    position: fixed;
    padding: 15px 160px;
    width: 375px;
  }
  .backimg .front .Enter-site-home .landing_typed{
  margin-left: 0px;
  text-align: center;
  width: 374px;
    
  }
  .Enter-site-home button {
    height: 40px;
    font-size: 10px;
    line-height: 5px;
  }
  .bottomforthefront{
    margin-left: -20px;
  }
}

/* body end */
/*  discription */
.Tracker-disc {
  display: flex;
  justify-content: center;
  margin-top: 170px;
  margin-bottom: 170px;
}

.why-tracker {
  display: grid;

  grid-template-columns: repeat(3, 5fr);
  margin-top: -80px ;
}
.services-card {
  position: relative;
  padding: 10px;
  color: var(--primary-color-Navy-light);
  padding-top: 10px;
  width: 320px;
  height: 180px;
  box-shadow: 0 1px 5px var(--primary-color-Navy-light);
  border-radius: 10px;
  /* background-size: cover; */
  transition-duration: 300ms;
  transition-property: transform, box-shadow;
  transition-timing-function: ease-out;
  transform: rotate3d(0);
}

.services-card:hover {
  transition-duration: 150ms;
  box-shadow: 0 2px 1px 1px var(--primary-color-Navy-light);
}

.services-card .glow {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-image: radial-gradient(circle at 50% -20%, #ffffff22, #0000000f);
}
.row_two {
  margin-top: 30px;
  margin-right: 30px;
}
/* ===Description responsive=== */
@media only screen and (max-width: 375px) {
  .services-card {
  margin-left: 30px;
  }
 
}
/* discription end */
/* Partner component stylesheet */
.partenerts-container {
  width: 100%;
  height: 350px;
  background-color: var(--primary-color-Navy-light);
  margin-bottom: 170px;
}

.partners {
  margin: 0 auto;
  padding-left: 60px;
}

.partner-cont {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 100px;
  gap: 50px;
  padding-top: 130px;
}

.partner-cont .box img {
  background-color: white;
  border-radius: 5%;
  width: 200px;
  height: 85px;
}
.partenerts-container h1{
  color: var(--nav-text);
  font-size: 400%;
  text-align: center;
}

/* === Partners Responsive=== */
@media only screen and (max-width: 375px) {

  .partenerts-container h1{
    font-size: 200%;
  }
  .partners .partner-cont .box img {

    width: 150px;
    height: 55px;
    margin-left: -55px;
  }
}
/* Partner component stylesheet end */

/*  Testimonial stylesheet */

.testimonial_header {
  text-align: center;
  margin-bottom: 200px;
  margin-top: 2px;
}

.testimonial_header h1 {
  font-size: 40px;
  text-align: center;
  padding-bottom: 30px;
}

.customer_tetsimonial {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  color: black;
  display: inline-block;
  font-family: "Roboto", Arial, sans-serif;
  font-size: 16px;
  margin: 35px 10px 10px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  max-width: 310px;
  min-width: 250px;
  position: relative;
  text-align: center;
  width: 100%;
  background-color: #ffffff;
  border-radius: 5px;
  border-top: 5px solid #f4af1b;
}

.customer_tetsimonial *,
.customer_tetsimonial *:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.1s ease-out;
  transition: all 0.1s ease-out;
}

.customer_tetsimonial figcaption {
  padding: 13% 10% 12%;
}

.customer_tetsimonial figcaption:before {
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  color: #f4af1b;
  content: "\f10e";
  font-family: "FontAwesome";
  font-size: 32px;
  font-style: normal;
  left: 50%;
  line-height: 60px;
  position: absolute;
  top: -30px;
  width: 60px;
}

.customer_tetsimonial h3 {
  color: black;
  font-size: 20px;
  font-weight: 300;
  line-height: 24px;
  margin: 10px 0 5px;
}

.customer_tetsimonial h4 {
  font-weight: 400;
  margin: 0;
  opacity: 0.5;
}

.customer_tetsimonial blockquote {
  font-style: italic;
  font-weight: 300;
  margin: 0 0 20px;
}

/* footer */
.footer {
  background-color: var(--primary-color-Navy-light);
  padding: 40px 0;
}
.container {
  max-width: 1200px;
  margin: auto;
}
.social-links {
  display: grid;
  justify-content: space-between;
  align-items: center;
}

.container .row {
  display: flex;
  justify-content: space-between;
}

ul {
  list-style: none;
}

.footer-col h4 {
  font-size: 18px;
  color: var(--nav-text);
  text-transform: capitalize;
  margin-bottom: 25px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  position: relative;
}

.footer-col h4::before {
  content: " ";
  position: absolute;
  bottom: -5px;
  background-color: var(--nav-text);
  height: 2px;
  box-sizing: border-box;
  width: 100px;
}

.footer-col ul .list {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  padding-top: 7px;
  text-transform: capitalize;
  color: var(--primary-color-orange-light);
  text-decoration: none;
  font-weight: 400;
  display: block;
}
.add {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  padding-top: 7px;
  text-transform: capitalize;
  color: var(--primary-color-orange-light);
  text-decoration: none;
  font-weight: 400;
  display: block;
}

.footer-col ul .list:hover {
  margin-left: 6px;
  transition: var(--tran-04);
}

.footer-col .social-links .social {
  width: 90px;
  margin-left: 30px;
  transition: all 0.5s ease;
}
.social-icon {
  min-width: 20px;
  width: 0%;
  height: 10%;
  margin-top: 6px;
  color: var(--primary-color-orange-light);
}
.address-icon {
  margin-right: 10px;
  color: var(--primary-color-orange-light);
}
.footer-col .social-links .social:hover {
  filter: invert(80%);
}

.footer-col .partners img {
  width: 120px;
  height: 50px;
  margin-left: -70px;
  border-radius: 5px;
  background-color: white;
}

.footer-col .social-links img {
  padding-top: 9px;
  height: 35px;
  color: white;
}

/* footer end */

/* ==== Help Page ==== */

.help-page-header {
  text-transform: capitalize;
  color: var(--nav-text);
  font-size: 30px;
  padding-top: 70px;
  text-align: center;
}

.Help-page-body {
  color: #fff;
  overflow-x: hidden;
}

.Help-page-body {
  background: rgb(2, 0, 36);
  background: linear-gradient(
    30deg,
    var(--primary-color-Navy-light) 0%,
    var(--primary-color-Navy-light) 20%,
    rgb(58, 58, 241) 100%
  );
  padding: 60px 0;
}
.reveal {
  border-radius: 10px;
}
.Help-page-body ul li {
  background: rgb(255, 255, 255);
}

.Help-page-body ul li {
  list-style-type: none;
  position: relative;
  width: 6px;
  margin: 0 auto;
  padding-top: 10px;
}

.Help-page-body ul li::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: inherit;
}

.Help-page-body ul li div {
  position: relative;
  bottom: 0;
  width: 400px;
  padding: 15px;
  background: var(--primary-color-Navy-light);
}

.Help-page-body ul li div::before {
  content: "";
  position: absolute;
  bottom: 7px;
  width: 0;
  height: 0;
  border-style: solid;
}

.Help-page-body ul li:nth-child(odd) div {
  left: 45px;
  box-shadow: 10px 10px 1px rgba(0, 0, 0, 0.2);
}

.Help-page-body ul li:nth-child(odd) div::before {
  left: -15px;
  border-width: 8px 16px 8px 0;
  border-color: transparent var(--primary-color-Navy-light) transparent
    transparent;
}

.Help-page-body ul li:nth-child(even) div {
  left: -439px;
  box-shadow: -10px 10px 1px rgba(0, 0, 0, 0.2);
}

.Help-page-body ul li:nth-child(even) div::before {
  right: -15px;
  border-width: 8px 0 8px 16px;
  border-color: transparent transparent transparent
    var(--primary-color-Navy-light);
}

.Help-page-body phase {
  display: block;
  font-size: 19px;
  font-weight: bold;
  margin-bottom: 0px;
}

/*  ==== Help FAQ===== */

.help-faq-container {
  margin: 0 auto;
  padding: 64px;
  width: 768px;
}

.accordion button {
  position: relative;
  display: block;
  text-align: left;
  width: 100%;
  padding: 16px 0;
  color: var(--nav-text);
  font-size: 20px;
  font-weight: 400;
  border: none;
  background: none;
  outline: none;
}

.accordion button:hover,
.accordion button:focus {
  cursor: pointer;
  color: var(--primary-color-Navy-light);
}

.accordion button:hover::after,
.accordion button:focus::after {
  cursor: pointer;
  color: var(--primary-color-Navy-light);
  border: 1px solid var(--primary-color-Navy-light);
}

.accordion button .accordion-title {
  padding: 16px 24px 16px 0;
}

.help-card {
  width: 50%;
  margin: 30px auto;
  border-radius: 25px;
  background: rgba(162, 167, 168, 0.2);
  backdrop-filter: blur(100px);
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 110px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  box-sizing: border-box;
}
.accordion-item p {
  width: 80%;
  padding-left: 15px;
  padding-right: 15px;
  background: rgba(162, 167, 168, 0.2);
  border-radius: 6px;
  color: #ffffff;
}

/* ====Help Contact===== */

.Help-contact div {
  padding-top: 5%;
  padding-right: 15%;
  padding-left: 15%;
}

.feedback-input {
  color: white;
  font-weight: 500;
  font-size: 18px;
  border-radius: 5px;
  line-height: 22px;
  background-color: transparent;
  border: 2px solid var(--nav-text);
  transition: all 0.3s;
  padding: 13px;
  margin-top: 20px;
  width: 100%;
  box-sizing: border-box;
  outline: 0;
}

.Help-contact textarea {
  height: 150px;
  line-height: 150%;
  resize: vertical;
}

.Help-contact-button {
  background: #f4af1b;
  padding: 6px 20px;
  border-radius: 6px;
  color: #00154f;
  cursor: pointer;
}

.Help-contact-button:hover {
  background: #946604;
  transition: 0.2s;
  color: #0cd1d1;
}
.Help-contact {
  height: 450px;
  width: 50%;
  margin: 30px auto;
  border-radius: 25px;
  background: rgba(162, 167, 168, 0.2);
  backdrop-filter: blur(100px);
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 110px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}
.accordion-icon {
  display: inline-block;
  width: 13px;
  height: 13px;
  border: 1px solid;
  border-radius: 22px;
  color: rgba(255, 255, 255, 0.8);
}

/* ===== Services Page===== */
.Service-one {
  display: flex;

  padding-right: 1%;
  padding-left: 5%;
}
.Service-two {
  display: flex;
  justify-content: space-evenly;
}
.Service-three {
  display: flex;
  justify-content: space-evenly;
}
.Service-four {
  display: flex;
}
.left {
  flex: 1;
}

.right {
  flex: 1;
  padding-right: 1%;
  padding-left: 5%;
}
.help-page-header2 {
  padding-left: 60%;
  padding-top: 5%;
  padding-bottom: 1%;
}
.help-page-header3 {
  text-align: start;
  padding-left: 10%;
  padding-top: 5%;
}

.Service-one .left {
  padding-left: 1%;
}
.Service-three .left {
  padding-left: 1%;
  padding-top: 2%;
}

/* Profile DropDown */

.Profile_Container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.card {
  margin-top: 50px;
  margin-right: 250px;
  display: inline-block;
  background-color: var(--primary-color-Navy-light);
  width: 550px;
  height: 350px;
  border-radius: 4px;
  position: relative;
  box-shadow: 0px 5px 10px rgba(1, 1, 1, 0.5);
}
.card .top-section {
  color: var(--nav-text);
  height: 50px;
  width: 300px;
  border-radius: 4px 4px 0 0;
}
.card .avatar {
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
}
.card .avatar img {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  border: 4px solid #fff;
  box-shadow: 0 3px 6px rgba(172, 192, 233, 0.5);
}
.Card_username {
  color: var(--nav-text);
  position: absolute;
  top: 140px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 35px;
}
.Card_email {
  color: var(--nav-text);
  position: absolute;
  top: 180px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 20px;
}
.Card_site_name {
  color: var(--nav-text);
  position: absolute;
  top: 210px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  font-size: 25px;
}
.Profile-icons {
  display: flex;
  font-size: 30px;
  color: var(--nav-text);
  position: absolute;
  top: 300px;
  left: 70%;
  transform: translateX(-90%);
  text-align: center;
  column-gap: 85px;
}

.Profile-icons-logout:hover::before {
  content: "Logout";
  position: absolute;
  top: 100%;
  left: 105%;
  transform: translateX(-80%);
  background-color: #000000;
  color: var(--nav-text);
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  transition: width 1s ease-in;
}
.Profile-icons-setting:hover::before {
  content: "Settings";
  position: absolute;
  top: 100%;
  left: 35%;
  transform: translateX(-80%);
  background-color: #000000;
  color: var(--nav-text);
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  transition: width 1s ease-in;
}

/* ===Help dropdown=== */
.help-modal-container {
  position: relative;
}

.help-modal-btn {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  color: var(--nav-text);
}
/* ===Help dropdown=== */
.help-modal-container {
  position: relative;
}

.help-modal-btn {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  color: var(--nav-text);

  font-size: 16px;
  font-weight: 600;
}

.help-modal-btn i {
  margin-right: 0px;
}

.help-modal-btn-text {
  margin-left: 5px;
}

.help-modal-content {
  position: absolute;
  z-index: 1;
  top: 60px;
  right: 0;
  width: 350px;
  background-color: var(--primary-color-Navy-light);
  border-radius: 4px;
  box-shadow: 0px 5px 10px rgba(1, 1, 1, 0.5);
}

.help-modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #00000000;
  padding: 10px;
  border-radius: 4px 4px 0 0;
  color: var(--nav-text);
  box-shadow: 0px 2px 5px rgba(1, 1, 1, 0.5);
}

.help-modal-title {
  font-size: 18px;
  font-weight: 600;
}
.help-modal-body {
  padding: 10px;
}
.help-modal-body p {
  color: var(--nav-text);
}

.help-poper .accordion button {
  width: 100%;
  color: var(--nav-text);
  font-size: 15px;
  padding-left: 5%;
  box-shadow: 0px 0px 5px rgba(1, 1, 1, 0.5);
  border-radius: 5px;
  margin-left: -20px;
}
.accordion-item p {
  width: 100%;
  margin-left: -15px;
  color: var(--nav-text);
  font-size: 12px;
  box-shadow: 0px 0px 10px rgba(1, 1, 1, 0.5);
}
.help-poper .accordion-icon {
  color: var(--nav-text);
}
.help-poper .help-faq-container {
  margin: 0 auto;
  padding: 10px;
  width: 349px;
}

.help-modal-btn-text {
  margin-left: 5px;
}

.help-modal-content {
  position: absolute;
  z-index: 1;
  top: 60px;
  right: 0;
  width: 350px;
  background-color: var(--primary-color-Navy-light);
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
}

.help-modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #00000000;
  padding: 10px;
  border-radius: 4px 4px 0 0;
  color: var(--nav-text);
}

.help-modal-title {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
}
.help-modal-body {
  padding: 10px;
}
.help-modal-body p {
  color: var(--nav-text);
}

.help-poper .accordion button {
  width: 100%;
  color: var(--nav-text);
  font-size: 15px;
  text-align: start;
}
.accordion-item p {
  width: 90%;
  padding-left: 2px;
  padding-right: 2px;
  color: var(--nav-text);
  font-size: 12px;
}
.help-poper .accordion-icon {
  color: var(--nav-text);
}
.help-poper .help-faq-container {
  margin: 0 auto;
  padding: 10px;
  width: 349px;
}
