* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root {
  --primary-color-Navy-light: #041C32;
  --primary-color-blue-light: #3E6D9C;
  --primary-color-orange-light: #FD841F;
  --primary-color-red-light: #E14D2A;
  --primary-color-light: #DDE6ED;
  --search-text-color: #707070;
  --main-color: #30475e;
  --sidebar-color: #3795BD;
  --toggle-body-color: #222831;
  --main-body-color: #9DB2BF;
  --nav-text: #eeeeee;
  --error-color: #b30000ee;

  --secondery-color-black-dark: #041C32;
  --secondery-color-navy-dark: #04293A;
  
  /* ====== Transition ====== */
  --tran-03: all 0.2s ease;
  --tran-03: all 0.3s ease;
  --tran-04: all 0.3s ease;
  --tran-05: all 0.3s ease;
}
::selection {
  background-color: beige;
  color: var(--primary-color-blue-light);
}