@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
@import url("../../Assets/theme.css");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.Analytics-Dashboard {
  width: 100%;
  background: var(--main-color);
}

.Sidebar-body {
  min-height: 100vh;
  transition: var(--tran-05);
}

.Sidebar-body.dark {
  background-color: var(--nav-text);
}
.Sidebar-body.dark .All-left-content {
  background-color: var(--nav-text);
}
.RealTimeData {
  display: flex;
  flex-wrap: wrap;
}
.items {
  height: fit-content;
  margin: 10px;
  flex-grow: 1;
  flex-basis: 300px;
  background-color: var(--primary-color-Navy-light);
  border-radius: 10px;
}
.Sidebar-body.dark .items {
  background-color: var(--primary-color-light);
}
/* ===== Sidebar ===== */
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 18%;
  padding: 10px 14px;
  background: var(--primary-color-Navy-light);
  transition: var(--tran-05);
  z-index: 100;
}

.Sidebar-body.dark .sidebar {
  background: var(--main-body-color);
}

.close {
  width: 88px;
}
.sidebar li {
  height: 50px;
  list-style: none;
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.sidebar header .image,
.sidebar .icon {
  min-width: 60px;
  border-radius: 6px;
}
.image img {
  width: 60px !important;
}
.icon {
  min-width: 30px;
  border-radius: 6px;
  height: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}
.text,
.icon {
  color: var(--primary-color-light);
  transition: var(--tran-03);
}

.text {
  font-size: 17px;
  font-weight: 500;
  white-space: nowrap;
  opacity: 1;
}

.close .text {
  visibility: hidden;
  opacity: 0;
}

/* =========================== */
header {
  position: relative;
}

header .image-text {
  display: flex;
  align-items: center;
}

header .logo-text {
  display: flex;
  flex-direction: column;
}

header .image-text .name {
  margin-top: 2px;
  font-size: 25px;
  font-weight: 800;
}

header .image-text .profession {
  font-size: 15px;
  letter-spacing: 1px;
  margin-top: -2px;
  display: block;
}
.Sidebar-body.dark .image-text .name,
.Sidebar-body.dark .image-text .profession {
  color: var(--primary-color-Navy-light);
}
/* Profile */
.sidebar header .image {
  background-color: var(--nav-text);
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar header .image img {
  width: 40px;
  border-radius: 6px;
}

.nav-link .active,
.Date-picker .active {
  background-color: var(--primary-color-blue-light);
}
.nav-link:hover {
  background-color: var(--primary-color-blue-light);
  border-radius: 6px;
}
/* End Profile */
.sidebar header .toggle {
  position: absolute;
  top: 50%;
  right: -25px;
  transform: translateY(-50%) rotate(180deg);
  height: 25px;
  width: 25px;
  background-color: var(--primary-color-Navy-light);
  color: var(--nav-text);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  cursor: pointer;
  transition: var(--tran-05);
}

.Sidebar-body.dark .sidebar header .toggle {
  color: var(--primary-color-Navy-light);
  background: var(--main-body-color);
}

.close span .toggle {
  transform: translateY(-50%) rotate(0deg);
}

.menu {
  margin-top: 40px;
}

li.search-box {
  border-radius: 6px;
  background-color: var(--primary-color-light);
  cursor: pointer;
  transition: var(--tran-05);
}

li.search-box input {
  height: 100%;
  width: 100%;
  outline: none;
  border: none;
  background-color: var(--primary-color-light);
  color: var(--search-text-color);
  border-radius: 6px;
  font-size: 17px;
  font-weight: 500;
  transition: var(--tran-05);
}

li.search-box .icon {
  color: var(--primary-color-Navy-light);
}

li a {
  list-style: none;
  height: 100%;
  background-color: transparent;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  border-radius: 6px;
  text-decoration: none;
  transition: var(--tran-03);
}
.Token li a:hover {
  background-color: var(--main-color);
}

li a .icon {
  color: var(--sidebar-color);
}

.Sidebar-body.dark .sidebar li a .icon,
.Sidebar-body.dark .sidebar li a .text {
  color: var(--primary-color-Navy-light);
}

li a:hover .icon,
li a:hover .text {
  color: var(--sidebar-color);
}

.Sidebar-body.dark .sidebar li a:hover .icon,
.Sidebar-body.dark .sidebar li a:hover .text {
  color: var(--nav-text);
}

.sidebar .menu-bar {
  height: calc(100% - 55px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: scroll;
}

.menu-bar::-webkit-scrollbar {
  display: none;
}
.sidebar .menu-bar .mode {
  border-radius: 6px;
  background-color: var(--main-color);
  position: relative;
  transition: var(--tran-05);
}
.menu-bar .mode .sun-moon {
  display: flex;
}
.Sidebar-body.dark .menu-bar .mode .sun-moon {
  position: absolute;
  left: -55px;
  transition: var(--tran-04);
}
.mode-text {
  position: absolute;
  left: 25%;
}
.menu-bar .mode .sun-moon .icon {
  height: 25px;
  width: 35px;
}
.mode .sun-moon .icon {
  position: relative;
}

.mode .sun-moon .icon.sun {
  opacity: 0;
}

.Sidebar-body.dark .mode .sun-moon .icon.sun {
  opacity: 1;
}

.Sidebar-body.dark .mode .sun-moon .icon.moon {
  opacity: 0;
}

.menu-bar .bottom-content .toggle-switch {
  position: absolute;
  right: 0;
  height: 100%;
  min-width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  cursor: pointer;
}

.toggle-switch .switch {
  position: relative;
  height: 22px;
  width: 40px;
  border-radius: 25px;
  background-color: var(--toggle-body-color);
  transition: var(--tran-05);
}

.switch::before {
  content: "";
  position: absolute;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  background-color: var(--sidebar-color);
  transition: var(--tran-04);
}

.Sidebar-body.dark .switch::before {
  left: 20px;
}

.home {
  position: relative;
  left: 19%;
  /* height: 100vh; */
  width: calc(100% - 20%);
  transition: var(--tran-05);
}

.sidebar.close ~ .home {
  left: 7%;
  width: calc(100% - 9%);
}

.Sidebar-body.dark .home {
  background-color: var(--primary-color-light);
}

.Top-Header {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  width: 100%;
}

.Date-picker {
  display: flex;
}

.period {
  color: var(--primary-color-light);
  padding: 6px 8px;
  cursor: pointer;
}
.Sidebar-body.dark .Top-Header .period {
  color: var(--primary-color-Navy-light);
}
.Sidebar-body.dark .Top-Header .period:hover {
  background-color: var(--primary-color-Navy-light);
  color: var(--nav-text);
}
.period:hover {
  background-color: var(--primary-color-Navy-light);
  color: var(--primary-color-light);
}

.right-cont {
  display: flex;
  align-items: center;
}

.profile-img {
  width: 35px;
  height: 35px;
  background: var(--nav-text);
  border-radius: 50%;
}
.Sidebar-body.dark .Top-Header .profile-img {
  background: var(--primary-color-Navy-light);
}
.clildren-class {
  margin-top: 10px;
}
.dash-errors{
  color: red;
  background-color: var(--primary-color-Navy-light);
  border-radius: 4px;
  padding: 1%;
  text-align: center;
}
.profile-icon {
  width: 20px;
  height: 20px;
  padding-right: 20px;
  color: var(--nav-text);
}
.profile-icon-inverted {
  width: 20px;
  height: 20px;
  padding-right: 20px;
  color: var(--primary-color-Navy-light);
}
.Sidebar-body.dark .Top-Header .profile-icon {
  color: var(--primary-color-Navy-light);
}
.card-cont {
  display: grid;
  grid-template-columns: repeat(2, minmax(auto, 1fr));
  gap: 15px;
}
@media (min-width: 1000px) {
  /* .sidebar {
    width: 88px;
  } */
  /* .sidebar header {
    display: none;
  }*/
  /* .home {
    left: 10%;
    width: calc(100% - 12%);
  }  */
}
@media (max-width: 800px) {
  .card-cont {
    grid-template-columns: repeat(1, minmax(auto, 1fr));
  }
  /* .home {
    left: 12%;
    width: calc(100% - 13%);
  } */
}
@media (max-width: 700px) {
  /* .home {
    left: 14%;
    width: calc(100% - 15%);
  } */
}
.card-visitor-cont {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 10px;
}

.Page-card {
  display: grid;
  grid-template-columns: repeat(1, minmax(auto, 1fr));
  gap: 15px;
}

.one {
  height: fit-content;
  border-radius: 10px;
  background-color: var(--primary-color-Navy-light);
  opacity: 1;
}
.Sidebar-body.dark .one {
  background-color: var(--primary-color-light);
}
.data-container-line {
  width: 80vw;
}
.data-line {
  height: 40vh;
}
.Device-Title {
  padding: 8px 20px;
}
.Device-Title h3 {
  color: var(--nav-text);
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 350;
}
.Sidebar-body.dark .Device-Title h3 {
  color: var(--primary-color-Navy-light);
  font-weight: 400;
}
.data {
  display: flex !important;
  justify-content: center !important;
  align-items: center;
}
.piechart {
  width: 320px !important;
  height: 320px !important;
  margin: 10px 20px !important;
}
.data-table {
  width: 100%;
  border-collapse: separate;
  table-layout: fixed;
}
.data-table thead th {
  color: var(--main-color);
  background-color: var(--nav-text);
  font-weight: 350;
  text-align: left;
  padding: 2px 20px;
}
.Sidebar-body.dark .data-table thead th {
  color: var(--primary-color-Navy-light);
}
.data-table th:last-child {
  text-align: right;
}

.data-table td {
  font-size: 15px;
  padding: 3px 15px;
  font-weight: 350;
}
.data-table td:first-child {
  display: flex;
  align-items: center;
  margin-left: 5px;
}
.data-table td:last-child {
  text-align: right;
  padding-right: 40px;
}
.data-table img {
  width: 25px;
  height: 25px;
  margin-right: 5px;
  color: var(--nav-text);
}
.data-table tbody tr:hover {
  background: var(--main-body-color);
}
.data-table tbody tr {
  color: var(--nav-text);
}
.Sidebar-body.dark .data-table tbody tr {
  color: var(--primary-color-Navy-light);
}
.table-footer {
  display: flex;
  justify-content: center;
  padding: 10px 0px;
}
.Location td {
  padding-left: 35px;
}
/* Vsitor */
.visitpagebottom {
  display: flex;
}
.containerForVisitorLog {
  width: 100%;
}
.containerVisitorLog {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 45px;
  margin-bottom: 5px;
  background-color: var(--main-body-color);
  align-items: center;
  border-radius: 10px;
  opacity: 0.7;
}
.visitor-information {
  padding: 2px 12px;
  opacity: 1;
}

.visitor-information img {
  width: 15px;
  height: 15px;
  margin-right: 8px;
}
.RealTimeDataview {
  height: fit-content;
}
.ActualData {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 5px;
  background-color: var(--nav-text);
}
.ActualData img {
  width: 25px;
  height: 25px;
}
.pagination {
  display: flex;
  align-items: center;
}
.page-link {
  position: relative;
  display: block;
  padding: 0.2rem 0.2rem;
  line-height: 1.25;
}
.pagination a{
  color: var(--nav-text);
  font-size: 14px;
}
.pagination a:hover,
.pagination .active {
    background-color: var(--primary-color-light) !important;
    color: var(--primary-color-Navy-light) !important;
}
span .visually-hidden {
  display: none;
}
/* =====  Notification PopUp ===== */
.pop-up-overlay {
  position: absolute;
  z-index: 1;
  top: 165px;
  right: 0;
  width: 350px;
  background-color: var(--primary-color-Navy-light);
  border-radius: 4px;
}
.pop-up-header-text {
  text-align: center;
  color: var(--nav-text);
  box-shadow: 0px 5px 10px rgba(1, 1, 1, 0.5);
  margin-top: -15px;
}
.pop-up {
  position: absolute;
  top: 2%;
  right: 1%;
  transform: translate(0, -35%);
  background-color: var(--primary-color-Navy-light);
  padding: 20px;
  border-radius: 6px;
  box-shadow: 0px 5px 10px rgba(1, 1, 1, 0.5);
}

.pop-up-data-table {
  width: 400px;
  border-collapse: separate;
  table-layout: fixed;
}

.pop-up-data-table tbody td {
  color: var(--nav-text);
  background-color: var(--primary-color-blue-light);
  border-radius: 5px;
}

.pop-up-data-table tbody td:hover {
  background-color: var(--search-text-color);
  opacity: 100%;
  color: #cac5c5;
}

.pop-up-data-table td {
  padding: 5px 25px;
  text-align: left;
  cursor: pointer;
}

/* ===== Add Site =====  */

.Addsite__Body {
  background-color: var(--primary-color-blue-light);
  font-size: 1em;
  color: white;
  height: 100%;
  margin: 0;
  padding-top: 10px;
  padding-left: 250px;
  padding-bottom: 80px;
}

.Addsite .form {
  margin-top: 2%;
  margin-left: 100px;
  padding: 0px;
  padding: 10%;
  align-items: center;
  width: 60%;
  height: 100%;
  background: var(--primary-color-Navy-light);
  border: 5px;
  border-radius: 5px;
}
.Addsite .form hr {
  width: 70%;
}
.Addsite p {
  background-color: #0a0a0a;
  color: #ffffff;
  opacity: 60%;
  padding: 2%;
}

.Addsite input[type="text"],
textarea,
select {
  width: 80%;
  background: none;
  border: none;
  outline: none;
}

.Addsite__Button {
  padding: 4px 29px 8px 29px;
  margin-top: 60px;
  color: #fff;
  background-color: var(--primary-color-blue-light);
  font-size: 18px;
  text-align: center;
  font-style: normal;
  border-radius: 5px;
  max-width: 500px;
  border: 1px solid var(--primary-color-Navy-light);
  border-width: 1px 1px 3px;
  box-shadow: 0 -1px 0 rgba(255, 255, 255, 0.1) inset;
  margin-bottom: 10px;
  cursor: pointer;
}

.Addsite__Button:hover {
  max-width: 530px;
  box-shadow: 0px 0px 10px #3a1919;
}

fieldset {
  margin-bottom: 30px;
  border: none;
}
legend b {
  padding-left: 70px;
  font-size: 30px;
}

/* ==== Preloader ==== */
.Preloader {
  background: none;
}

.Preloader {
  position: absolute;
  top: 380px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
}

.Preloader hr {
  border: 0;
  margin: 0;
  width: 20%;
  height: 20%;
  position: absolute;
  border-radius: 50%;
  animation: spin 2s ease infinite;
}

.Preloader :first-child {
  background: #19a68c;
  animation-delay: -1.5s;
}

.Preloader :nth-child(2) {
  background: #f63d3a;
  animation-delay: -1s;
}

.Preloader :nth-child(3) {
  background: #fda543;
  animation-delay: -0.5s;
}

.Preloader :last-child {
  background: #193b48;
}

@keyframes spin {
  0%,
  100% {
    transform: translate(0);
  }

  25% {
    transform: translate(160%);
  }

  50% {
    transform: translate(160%, 160%);
  }

  75% {
    transform: translate(0, 160%);
  }
}

/* ====== Addsite Token/Tracking code ======= */
.Token {
  color: var(--primary-color-blue-light);
  background: linear-gradient(
    30deg,
    var(--primary-color-Navy-light) 0%,
    rgb(231, 212, 212) 1%,
    rgb(58, 58, 241) 100%
  );
}

.Token_page {
  background-color: var(--primary-color-blue-light);
  font-size: 1em;
  color: white;
  height: 100%;
  margin: 0;
  padding-top: 10px;
  padding-left: 250px;
  padding-bottom: 80px;
}

.Token h1 {
  margin: 0 0 30px 0;
  text-align: center;
}

.Token p {
  background-color: #0a0a0a;
  color: #ffffff;
  opacity: 60%;
}
.tory p {
  font-size: 15px;
  margin-top: -10%;
  display: inline-block;
  padding: 2%;
}
.Token__Button {
  padding: 4px 29px 8px 29px;
  color: #fff;
  background-color: var(--primary-color-blue-light);
  font-size: 18px;
  text-align: center;
  font-style: normal;
  border-radius: 5px;
  max-width: 500px;
  border: 1px solid var(--primary-color-Navy-light);
  border-width: 1px 1px 3px;
  box-shadow: 0 -1px 0 rgba(255, 255, 255, 0.1) inset;
  margin-bottom: 10px;
  cursor: pointer;
}

.Token__Button:hover {
  max-width: 530px;
  box-shadow: 0px 0px 10px #3a1919;
}

legend {
  font-size: 1.4em;
  margin-bottom: 10px;
}

.Token form {
  margin-top: 2%;
  margin-left: 100px;
  padding: 0px;
  padding: 2%;
  align-items: center;
  width: 60%;
  height: 100%;
  background: var(--primary-color-Navy-light);
  border: 5px;
  border-radius: 5px;
  font-size: 12px;
}

.card-front {
  width: 100%;
  height: 100%;
  display: inline-block;
  border-radius: 5px;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.4);
  overflow: hidden;
  background: black;
}
.card-front p {
  background-color: #0a0a0a;
  color: #ffffff;
  opacity: 60%;
  width: 100%;
}
.Copy_button {
  text-align: right;
  margin-top: 0px;
}
/* Dashboard */
.errors {
  color: rgb(255, 44, 44);
  font-size: 20px;
  font-weight: 370;
  padding-left: 10px;
}
.container-dash {
  display: flex;
  flex-wrap: wrap;
}

.dash {
  flex-grow: 1;
  flex-basis: 150px;
  margin: 6px;
  border-radius: 10px;
  background-color: var(--primary-color-Navy-light);
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
}
.Sidebar-body.dark .dash {
  background-color: var(--main-body-color);
}
.data {
  flex-grow: 1;
  padding: 6px 20px;
}
.container-dash .titles .dash-icon {
  height: 40px;
  color: var(--nav-text);
}
.titles span {
  display: flex;
  justify-content: center;
}
.data h3 {
  color: var(--nav-text);
  font-size: 20px;
  font-weight: bolder;
}
.Sidebar-body.dark .data h3,
.Sidebar-body.dark .titles h3,
.Sidebar-body.dark .container-dash .titles .dash-icon {
  color: var(--primary-color-Navy-light);
}
.titles h3 {
  color: var(--nav-text);
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 200;
}

/* === Dashboard Settings === */
.settings-body {
  background: none;
  font-size: 1em;
  color: white;
  padding-left: 250px;
  padding-top: 40px;
}
.settings-body h1 {
  padding-left: 25%;
}
.settings-body button {
  float: center;
  background-color: var(--primary-color-blue-light);
  border-radius: 10px;
  height: 45px;
  width: 80px;
  color: var(--nav-text);
  cursor: pointer;
  margin-top: 15px;
  margin-left: 125px;
  text-align: center;
}
.settings-body .try {
  margin-top: 2%;
  padding: 5%;
  padding-left: 10%;
  width: 60%;
  height: 100%;
  background: var(--primary-color-Navy-light);
  border: 5px;
  border-radius: 5px;
}
.settings-body a {
  text-decoration: none;
  color: white;
}
.settings-body input {
  width: 80%;
  background: none;
  border: none;
  outline: none;
}

.settings-body hr {
  width: 80%;
}
.settings-user-email {
  margin-left: 100px;
}
.settings-body h1 {
  margin-top: 20px;
}
.settings-body h2 {
  margin-top: 40px;
  /* text-align: center; */
  margin-left: 100px;
}
.settings-body .avatar {
  position: absolute;
  top: 20%;
  left: 320px;
  transform: translateX(-50%);
}
.settings-body .avatar img {
  border-radius: 50%;
  width: 130px;
  height: 130px;
  border: 4px solid #fff;
  box-shadow: 0 3px 6px rgba(172, 192, 233, 0.5);
}
.upload-picture{
  position: absolute;
  top: 35%;
  left: 260px;
  width: 100%;
  border-radius: 50px;
}
/* ====Integrated code==== */
.tracking-code {
  margin-left: -50px;
  margin-top: 0px;
  width: 550px;
  height: 400px;
  padding-left: 5%;
  border-radius: 5px;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.4);
  overflow: hidden;
  background: black;
}
.IntegratedCode {
  background-color: rgb(15, 15, 15);
  width: 500px;
  opacity: 50%;
  padding: 10%;
  height: 280px;
}

.done_checkmark {
  display: flex;
  background: none;
  color: rgb(8, 238, 8);
  margin-top: 30px;
  font-size: 20px;
  background-color: none;

  border-radius: 5px;
  width: 100%;
}
.Copy_button {
  margin-right: 15px;
  margin-top: -40px;
}
